import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { NotificationType, sendNotification } from '@tk/utilities/tk.notification';

interface ActionItem {
    id: string;
    affiliation: string;
    revenue: string;
    tax: string;
    shipping: string;
}

interface ProductItem {
    id: string;
    name: string;
    brand: string;
    category: string;
    price: string;
    quantity: string;
    position: string;
}

export default class TKManager extends TKCustomElementFactory {
    messageContainerSelector: string;
    basketId?: HTMLElement;
    eventName: string;
    sessionTimeoutWrapper?: HTMLElement;
    sessionTime?: number;

    constructor() {
        super();

        this.eventName = this.getAttribute('data-tk-event-name') || 'event-purchase';
        this.basketId = this.querySelector('[data-tk-otm-basket-id]') || undefined;
        this.messageContainerSelector = (
            this.getAttribute('data-tk-message-container-selector')
            || '[id^="wp_messages_container_"]'
        );
        this.sessionTimeoutWrapper = this.querySelector('[data-tk-session-timeout-wrapper]') || undefined;
        this.sessionTime = Number(this.sessionTimeoutWrapper?.getAttribute('data-tk-session-time') || 0);
    }

    connectedCallback(): void {
        this.processNotification();

        this.sessionTimeoutWrapper && this.showSessionTimeout();

        if (!this.basketId || !window.dataLayer) return;
        const data = this.getPurchaseData();
        data && window.dataLayer.push(data);
    }

    processNotification() {
        const messageContainer = document.querySelector(this.messageContainerSelector);
        if (!messageContainer || !messageContainer.hasChildNodes()) return;

        const infoMessage = messageContainer.querySelectorAll<HTMLElement>('.Info');
        const errorMessage = messageContainer.querySelectorAll<HTMLElement>('.Error');
        const warningMessage = messageContainer.querySelectorAll<HTMLElement>('.Warning');
        const debugMessage = messageContainer.querySelectorAll<HTMLElement>('.Debug');

        const messageList = Array.from([...infoMessage, ...errorMessage, ...warningMessage, ...debugMessage]);

        const types: Record<string, NotificationType> = {
            Info: NotificationType.SUCCESS,
            Error: NotificationType.ERROR,
            Warning: NotificationType.WARNING,
            Debug: NotificationType.INFO,
        };

        messageList.forEach((item) => {
            const type = types[item.className];
            const childList = Array.from(item.firstChild?.childNodes || []);
            const message = childList.find((item) => item instanceof Text)?.textContent || '';
            const code = item.querySelector('.was-message-code')?.textContent || '';
            const addition = item.querySelector('.was-message-addition')?.textContent || '';
            const wait = Number(item.querySelector('.was-message-wait')?.textContent) || undefined;
            const consoleMessage = `${code}: ${message}} (wait: ${wait} sec.${addition})`;

            sendNotification(type, message, wait, { consoleMessage });
        });
        messageContainer.remove();
    }

    showSessionTimeout() {
        setTimeout(() => {
            this.sessionTimeoutWrapper!.hidden = false;
        }, this.sessionTime);
    }

    getPurchaseData() {
        return {
            event: 'event-purchase',
            ecommerce: {
                purchase: {
                    actionField: this.getActionData(),
                    products: this.getProductData(),
                },
            },
        };
    }

    getActionData() {
        const affiliation = this.querySelector<HTMLElement>('[data-tk-otm-affiliation]');
        const revenue = this.querySelector<HTMLElement>('[data-tk-otm-revenue]');
        const tax = this.querySelector<HTMLElement>('[data-tk-otm-tax]');
        const shipping = this.querySelector<HTMLElement>('[data-tk-otm-shipping]');
        return {
            id: this.basketId!.innerText,
            affiliation: affiliation ? affiliation.innerText : '',
            revenue: revenue ? revenue.innerText : '',
            tax: tax ? tax.innerText : '',
            shipping: shipping ? shipping.innerText : '',
        } as ActionItem;
    }

    getProductData() {
        const data: ProductItem[] = [];
        const items = this.querySelectorAll<HTMLElement>('[data-tk-otm-item]');
        Array.from(items).forEach((item) => {
            const id = item.querySelector<HTMLElement>('.otm-id');
            const name = item.querySelector<HTMLElement>('.otm-name');
            const brand = item.querySelector<HTMLElement>('.otm-brand');
            const category = item.querySelector<HTMLElement>('.otm-category');
            const price = item.querySelector<HTMLElement>('.otm-price');
            const quantity = item.querySelector<HTMLElement>('.otm-quantity');
            const position = item.querySelector<HTMLElement>('.otm-position');
            data.push({
                id: id ? id.innerText : '',
                name: name ? name.innerText : '',
                brand: brand ? brand.innerText : '',
                category: category ? category.innerText : '',
                price: price ? price.innerText : '',
                quantity: quantity ? quantity.innerText : '',
                position: position ? position.innerText : '',
            });
        });
        return data;
    }
}
