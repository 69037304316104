import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class JUStartScan extends TKCustomElementFactory {
    cameraButton?: HTMLButtonElement;
    form?: HTMLFormElement;
    input?: HTMLInputElement;

    constructor() {
        super();

        // this.cameraButton = this.querySelector('[data-ju-camera]') || undefined;
        this.form = this.querySelector('[data-ju-scan-form]') || undefined;
        this.input = this.querySelector('[data-ju-device-input]') || undefined;
    }

    connectedCallback(): void {
        if (!this.form || !this.input) return;
        this.input!.focus();
        // this.registerEventListener();
    }

    registerEventListener() {
        const startScan = this.showForm.bind(this);
        this.pushListener({ event: 'click', element: this.cameraButton!, action: startScan });
    }

    showForm() {
        this.form!.hidden = false;
        this.input!.focus();
    }
}
